export const visitorsIntercomSettings = () => {


  try {
    window.Intercom('boot', {
      app_id: 'gqwpk3sh',
      vertical_padding: 20,
    });
  } catch (error) {
    // do nothing
  }

  try {
    window.Intercom('update', {
      hide_default_launcher: false,
    });
  } catch (error) {
    // do nothing
  }
};

export const registrationIntercomSettings = () => {
  try {
    // window.Intercom('shutdown')
    window.Intercom('update', {
      hide_default_launcher: true,
      display: 'block',
    });
    // window.Intercom('hide')
  } catch (error) {
    console.log(error);
  }
};
