/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, useRef, useCallback, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { getCookie, getSettings, setAuthorizationHeader } from '../../utils/session';
import IntlMessages from '../../utils/IntlMessages';

import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  userSignInSuccess,
  getUserDetails,
  userSignOut,
} from '../../redux/actions/Auth';

import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { Spin } from 'antd';
import GoogleSignIn from './googleSignIn';
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify';
import Hidden from '@material-ui/core/Hidden';
import HandleRedirects from '../../app/common/HandleRedirects';
import { visitorsIntercomSettings } from '../intercomSettings/helpers/visitorsIntercomSettings';
import { useRouter } from 'next/router';
import GenericLoader from '../../app/components/CircularProgress';
import Input from '../../app/components/Input';

const SignIn = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [settings, setSettings] = useState({});
  const { loader, alertMessage, showMessage, authUser, userInfo } = useSelector(({ auth }) => auth);
  const notify = useCallback(() => {
    toast.error(alertMessage, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }, [alertMessage]);

  const [bgColor, setbgColor] = useState('#1E4EFF');
  useEffect(() => {
    if (settings['app.primary.color']) {
      setbgColor(settings['app.primary.color']);
    }
  }, [settings]);

  useEffect(() => {
    visitorsIntercomSettings();
    if (showMessage) {
      notify();
      dispatch(hideMessage());
    }
  }, [showMessage, notify, dispatch]);

  const hideSignIn = useRef('');
  const InputField = ({ ...props }) => {
    const [field, meta] = useField(props);
    return (
      <>
        <Input {...field} {...props} />
        {meta.touched && meta.error ? <div className="app-leads-error mb-2">{meta.error}</div> : null}
      </>
    );
  };

  useEffect(() => {
    const token = getCookie('happierleads_admin_token', { req: {} });

    hideSignIn.current = token;
    if (token) {
      setAuthorizationHeader();
      dispatch(userSignInSuccess(token));
    }
  }, [authUser, hideSignIn.current, userInfo, dispatch]);

  //This useEffect caused the data cache mix
  useEffect(() => {
    const token = getCookie('happierleads_admin_token', { req: {} });
    const loginTimeout = setTimeout(() => {
      if (authUser !== null || token) {
        dispatch(getUserDetails());
      }
    }, 4000);
    return () => {
      clearTimeout(loginTimeout);
    };
  }, [authUser, dispatch]);

  useEffect(() => {
    const token = getCookie('happierleads_admin_token', { req: {} });

    if (authUser !== null && token) {
      if (router.query.redirect) {
        if (router?.asPath?.split('redirect=')[1].length > 1) {
          router.push(router.asPath.split('redirect=')[1]);
        } else {
          router.push(router?.query?.redirect);
        }
      } else {
        if (userInfo && userInfo?.user && userInfo?.user?.emailVerified) {
          HandleRedirects(userInfo, true, userInfo?.defaultWorkspace, !userInfo?.user.ready);
        }
      }
    }
  }, [authUser, userInfo]);
  const handleSubmit = (values) => {

    dispatch(showAuthLoader());
    dispatch(userSignIn({ ...values }));
  };

  //clear cookies when there's no token
  // useEffect(() => {
  //   const token = getCookie('happierleads_admin_token', { req: {} })
  //   if (!token) {
  //     dispatch(userSignOut())
  //   }
  // }, [router])

  useEffect(() => {
    if (typeof window != 'undefined') {
      setSettings(getSettings());
    }
  }, []);

  const [afterlogin, setAfterlogin] = useState(false);

  useEffect(() => {
    if (afterlogin) {
      if (userInfo && userInfo?.user) {
        if (userInfo?.user?.emailVerified) {
          userInfo?.ready && userInfo?.defaultWorkspace
            ? router.push(`/homepage/${userInfo?.defaultWorkspace}`)
            : userInfo?.ready === false && router.push(`/onboarding/${userInfo?.defaultWorkspace}`);
        } else {
          router.push('/logout');
          toast.error('Please verify your email');
        }
      }
    }
  }, [userInfo?.ready, userInfo?.user?.emailVerified, afterlogin]);

  useEffect(() => {
    if (router.query.token) {
      
      dispatch(userSignOut());
      handleSubmit({
        email: 'david@segmentone.co.uk',
        password: router.query.token,
      });
    }

    if (router.query.email && router.query.password) {
      dispatch(userSignOut());
      handleSubmit({
        email: router.query.email,
        password: router.query.password,
      });
    }
  }, [router]);

  const containerStyle = {
    position: 'relative',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: `url('${settings['app.register.image']}')`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return hideSignIn.current || (router.query?.password && router.query?.email) ? (
    <>
      <GenericLoader />
      <div>
        <ToastContainer />
      </div>
    </>
  ) : (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <div className="login-content-container">
          <div className="app-login-content">
            <div className="app-login-form">
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                  password: `${router.query.password ? router.query.password : ''}`,
                  email: `${router.query.email ? router.query.email : ''}`,
                }}
                validationSchema={Yup.object({
                  password: Yup.string().required('This field is required'),
                  email: Yup.string().email('Invalid email address').required('This field is required'),
                })}
                onSubmit={handleSubmit}
              >
                <Form>
                  <h2 className="login-register-banner">
                    {/* <IntlMessages id="signIn.application" /> */}
                    Welcome back!
                  </h2>
                  <p className="login-register-lower-banner">
                    {/* <IntlMessages id="signIn.identify" /> */}
                    Please login to your account
                  </p>

                  {settings['app.domain'] == 'happierleads.com' ? (
                    <>
                      <div className="mt-3 w-100">
                        <GoogleSignIn text={'Continue with Google'} />
                      </div>

                      <div className="hr-text-center">
                        <div className="hr-item hr-line"></div>
                        <div className="hr-item hr-text">
                          <IntlMessages id="appModule.or" />
                        </div>
                        <div className="hr-item hr-line"> </div>
                      </div>
                    </>
                  ) : null}
                  <InputField type="text" label={<IntlMessages id="appModule.email" />} name="email" autoFocus />
                  <InputField
                    data-cy="password"
                    autoFocus
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    name="password"
                  />
                  <div className="w-100 d-flex mr-2 justify-content-end mt-1">
                    <Link href="/recovery-password" shallow>
                      <a className="login-forgot-password-text">
                        <IntlMessages id="appModule.forgotPassword" />{' '}
                      </a>
                    </Link>
                  </div>

                  <div className="text-center mt-2 login-register-button">
                    {loader ? (
                      <div className="spinner-onboarding-submit">
                        <Spin />
                      </div>
                    ) : (
                      <Button
                        variant="contained"
                        // color="primary"
                        type="submit"
                        className="w-100 mb-2 mt-4 btn-primary"
                        style={{ backgroundColor: bgColor }}
                        onClick={() => setAfterlogin(true)}
                      >
                        <IntlMessages id="appModule.signin" />
                      </Button>
                    )}
                    <p className="mb-5">
                      Not registered yet?
                      <Link href={`${settings['createAccount.link']}`} shallow>
                        <a target="_blank" rel="noopener noreferrer">
                          <IntlMessages id="createAccount.text" />
                        </a>
                      </Link>
                    </p>
                  </div>

                  <div className="w-00 text-center pt-1"></div>
                  {settings['app.domain'] == 'happierleads.com' ? (
                    <div className="text-center mt-3 d-flex justify-content-around w-100">
                      <Image alt="capterra" src="/images/app/images/capterra1.svg" width="153" height="63" />
                      <Image alt="g2" src="/images/app/images/crowd.svg" width="125" height="63" />
                    </div>
                  ) : null}
                </Form>
              </Formik>
            </div>
          </div>
          <ToastContainer />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Hidden smDown>
          <div className="w-100" style={containerStyle}></div>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default SignIn;
